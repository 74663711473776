/* Charts and Graphs overrides */

// Chartist pie charts (A, B, C are slices of the pie)
.ct-series-a .ct-slice-pie {
  fill: #fe7c96;
}

.ct-series-b .ct-slice-pie {
  fill: #fed713;
}

.ct-series-c .ct-slice-pie {
  fill: #198ae3;
}

.ct-label {
  fill: #000;
  font-size: 1rem;
  line-height: 1;
}
